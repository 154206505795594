<template>
  <div class="forgot-password-logged-user">
    <validate-code
      :mode="'forgot-password'"
      :channel="channel"
      :value="value"
      @resend="onResend"
      @cancel="onCancel"
      @submit="onValidateCode"
    />
  </div>
</template>

<script>

import ValidateCode from './ValidateCode'

export default {
  name: 'ForgotPasswordLoggedUser',
  components: {
    ValidateCode
  },
  props: ['isClinician'],
  data () {
    return {
      channel: '',
      value: '',
      access_code: ''
    }
  },
  mounted () {
    const user = this.$store.getters.myAccountData
    this.channel = user.contact_primary.email.enabled ? 1 : 2
    this.value = user.contact_primary.email.enabled ? user.contact_primary.email.value : user.contact_primary.phone.value
  },
  methods: {
    onCancel () {
      this.$route.push({ name: 'Login' })
    },
    onResend () {
      const data = {
        channel: parseInt(this.channel),
        value: this.value,
        forgot_password: true,
        username: this.$store.getters.myAccountData.username
      }
      this.$store.dispatch('GET_ACCESS_CODE', data)
        .then(response => {
          if (response && response.status !== 200) {
            this.$handleApiError(response)
          } else {
            this.$toast.success({ message: this.$t('activationSent') })
          }
        })
    },
    onValidateCode () {
      if (this.$store.state.security.resetToken && !this.isClinician) {
        this.$router.push({
          name: 'ResetPasswordLoggedUser',
          params: { key: this.$store.state.security.resetToken }
        })
      } else if (this.$store.state.security.resetToken) {
        this.$emit('success', this.$store.state.security.resetToken)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .forgot-password-logged-user{
    margin-top: 30px;
  }
</style>
